:root {
  --animation-duration: 0.3s;
  --animation-timing: cubic-bezier(0.4, 0, 0.2, 1);
  --spacing: 4px;
  --radius: 8px;
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  transition: background-color var(--animation-duration) var(--animation-timing);
  position: relative;
  overflow-x: hidden;
}

/* Header styles */
.header {
  padding: calc(var(--spacing) * 4) 0;
  position: relative;
  z-index: 10;
  backdrop-filter: blur(4px);
  transition: all var(--animation-duration) var(--animation-timing);
}

.studio-name {
  font-size: 32px;
  font-weight: 600;
  letter-spacing: -0.02em;
  background: linear-gradient(to right, #ffffff, #a0a0a0);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all var(--animation-duration) var(--animation-timing);
}

/* Main content styles */
main {
  flex: 1;
  position: relative;
  z-index: 1;
}

/* Project and experience card enhancements */
.experience-item,
.project-card {
  transition: transform var(--animation-duration) var(--animation-timing),
              box-shadow var(--animation-duration) var(--animation-timing),
              background-color var(--animation-duration) var(--animation-timing),
              border-color var(--animation-duration) var(--animation-timing);
  will-change: transform, box-shadow, background-color, border-color;
  position: relative;
  overflow: hidden;
  border-radius: var(--radius);
  backdrop-filter: blur(10px);
}

/* Add subtle gradient hover effect to cards */
.experience-item::before,
.project-card::before {
  content: '';
  position: absolute;
  inset: 0;
  background: radial-gradient(circle at top right, rgba(255,255,255,0.03) 0%, transparent 50%);
  pointer-events: none;
  opacity: 0;
  transition: opacity var(--animation-duration) var(--animation-timing);
}

.experience-item:hover::before,
.project-card:hover::before {
  opacity: 1;
}

/* Enhanced title animations */
.experience-title {
  transition: transform var(--animation-duration) var(--animation-timing);
  will-change: transform;
  background: linear-gradient(to right, #fff, #a0a0a0);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Loading animation enhancement */
@keyframes pulse {
  0%, 100% { opacity: 0.6; transform: scale(0.98); }
  50% { opacity: 1; transform: scale(1); }
}

/* Enhanced button and link styles */
.portfolio-link,
.contact-link {
  transition: transform var(--animation-duration) var(--animation-timing),
              background-color var(--animation-duration) var(--animation-timing),
              opacity var(--animation-duration) var(--animation-timing);
  will-change: transform, background-color, opacity;
  position: relative;
  overflow: hidden;
}

.portfolio-link::after,
.contact-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: currentColor;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform var(--animation-duration) var(--animation-timing);
}

.portfolio-link:hover::after,
.contact-link:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

/* Arrow animation enhancement */
.arrow {
  display: inline-block;
  transition: transform var(--animation-duration) var(--animation-timing),
              opacity var(--animation-duration) var(--animation-timing);
  will-change: transform, opacity;
}

.portfolio-link:hover .arrow,
.contact-link:hover .arrow {
  transform: translateX(4px);
  opacity: 1;
}

/* Footer enhancement */
.footer {
  position: relative;
  z-index: 5;
  padding-bottom: calc(var(--spacing) * 8);
  backdrop-filter: blur(5px);
  transition: all var(--animation-duration) var(--animation-timing);
}

.copyright {
  margin-top: calc(var(--spacing) * 10);
  opacity: 0.7;
  font-size: 12px;
  transition: opacity var(--animation-duration) var(--animation-timing);
}

.copyright:hover {
  opacity: 1;
}

/* Enhanced animation for sections */
.section-header {
  position: relative;
  overflow: hidden;
  margin-bottom: calc(var(--spacing) * 3);
}

.section-label {
  display: block;
  margin-bottom: calc(var(--spacing) * 2);
}

.section-header::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(to right, 
    rgba(255, 255, 255, 0.1), 
    rgba(255, 255, 255, 0.3), 
    rgba(255, 255, 255, 0.1));
  transform-origin: left;
}

/* Enhanced sparkles container */
.sparkles-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  opacity: 0.8;
  transition: opacity var(--animation-duration) var(--animation-timing);
}

/* Add media query for better performance on lower-end devices */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
  
  .sparkles-container {
    opacity: 0.4;
  }
}

/* Enhanced accessibility for focus states */
a:focus-visible,
button:focus-visible {
  outline: 2px solid rgba(255, 255, 255, 0.5);
  outline-offset: 2px;
  border-radius: 2px;
}

/* Add better responsive layout for mobile */
@media (max-width: 768px) {
  .experience-list {
    display: flex;
    flex-direction: column;
    gap: calc(var(--spacing) * 4);
  }
  
  .footer-content {
    padding: 0 calc(var(--spacing) * 4);
  }
}