:root {
  --text-primary: #ffffff;
  --text-secondary: #a0a0a0;
  --text-tertiary: #666666;
  --border-color: rgba(255, 255, 255, 0.1);
  --spacing: 8px;
  --radius: 12px;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Arial, sans-serif;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  background: #000000;
  color: var(--text-primary);
}

.main-content {
  max-width: 1000px;
  margin: 0 auto;
  padding: calc(var(--spacing) * 8);
}

.hero-section {
  margin-bottom: calc(var(--spacing) * 12);
}

.main-title {
  font-size: 48px;
  font-weight: 600;
  letter-spacing: -0.02em;
  margin-bottom: calc(var(--spacing) * 2);
}

.subtitle {
  font-size: 24px;
  color: var(--text-secondary);
  max-width: 600px;
}

.section-header {
  border-top: 1px solid var(--border-color);
  padding-top: calc(var(--spacing) * 3);
  margin-bottom: calc(var(--spacing) * 4);
}

.section-label {
  font-size: 14px;
  font-weight: 500;
  color: var(--text-secondary);
  letter-spacing: 0.05em;
}

.summary-text {
  font-size: 20px;
  line-height: 1.6;
  color: var(--text-secondary);
  max-width: 800px;
  margin-bottom: calc(var(--spacing) * 8);
}

.list-item {
  padding: calc(var(--spacing) * 4) 0;
  border-bottom: 1px solid var(--border-color);
}

.item-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: calc(var(--spacing) * 2);
}

.item-title {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.01em;
}

.item-role {
  font-size: 16px;
  color: var(--text-secondary);
  margin-bottom: calc(var(--spacing) * 1);
}

.item-description {
  font-size: 16px;
  color: var(--text-secondary);
  margin-bottom: calc(var(--spacing) * 2);
  max-width: 600px;
}

.duration {
  font-size: 16px;
  color: var(--text-tertiary);
}

.collaborators {
  font-size: 14px;
  color: var(--text-tertiary);
  margin-top: calc(var(--spacing) * 1);
}

.visit-link {
  font-size: 16px;
  color: var(--text-primary);
  text-decoration: none;
  display: inline-flex;
  align-items: center;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: calc(var(--spacing) * 4);
  margin-bottom: calc(var(--spacing) * 12);
}

.project-card {
  display: flex;
  flex-direction: column;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  padding: calc(var(--spacing) * 4);
  position: relative;
}

.project-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  background: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.2);
}

.project-link {
  text-decoration: none;
  color: inherit;
  display: block;
}

.project-preview {
  width: 100%;
  position: relative;
  padding-top: 56.25%; /* 16:9 aspect ratio by default */
  overflow: hidden;
  border-radius: var(--radius) var(--radius) 0 0;
  background: rgba(0, 0, 0, 0.2);
}

.preview-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain; /* Changed from cover to contain */
  object-position: center;
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  background: rgba(0, 0, 0, 0.8);
  padding: calc(var(--spacing) * 4);
}

.project-card:hover .preview-image {
  transform: scale(1.03);
}

.project-content {
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing) * 3);
  text-align: left; /* Explicitly set left alignment for desktop */
}

.project-icon {
  width: 64px;
  height: 64px;
  margin-bottom: calc(var(--spacing) * 3);
}

.icon-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.project-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: calc(var(--spacing) * 2);
}

.project-role {
  font-size: 16px;
  color: var(--text-secondary);
  margin-bottom: calc(var(--spacing) * 2);
}

.project-description {
  font-size: 16px;
  color: var(--text-secondary);
  line-height: 1.5;
}

.experience-section {
  margin-bottom: calc(var(--spacing) * 0);
}

.experience-item {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid var(--border-color);
  border-radius: var(--radius);
  padding: calc(var(--spacing) * 4);
  margin-bottom: calc(var(--spacing) * 3);
  backdrop-filter: blur(10px);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.experience-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.experience-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: calc(var(--spacing) * 2);
}

.experience-title {
  font-size: 24px;
  font-weight: 600;
  background: linear-gradient(to right, #fff, #a0a0a0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
}

.experience-role {
  font-size: 16px;
  color: var(--text-secondary);
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: 500;
}

.experience-description {
  font-size: 15px;
  color: var(--text-secondary);
  line-height: 1.6;
  margin: 0;
}

.duration {
  font-size: 16px;
  color: var(--text-tertiary);
  margin: 0;
  text-align: right;
}

.portfolio-link {
  display: inline-flex;
  align-items: center;
  gap: calc(var(--spacing) * 1);
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
  padding: calc(var(--spacing) * 1.5) calc(var(--spacing) * 3);
  background: rgba(255, 255, 255, 0.1);
  border-radius: calc(var(--radius) / 2);
  width: fit-content;
  margin-top: calc(var(--spacing) * 2);
}

.portfolio-link:hover {
  transform: translateX(4px);
  background: rgba(255, 255, 255, 0.15);
}

.experience-actions {
  margin-top: calc(var(--spacing) * 2);
}

.contact-links {
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing) * 2);
}

.contact-link {
  font-size: 16px;
  color: var(--text-primary);
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: calc(var(--spacing) * 1);
}

.arrow {
  font-size: 14px;
  transition: transform 0.3s ease;
  opacity: 0.7;
}

.portfolio-link:hover .arrow {
  transform: translateX(4px);
  opacity: 1;
}

@media (max-width: 768px) {
  .projects-grid {
    grid-template-columns: 1fr;
    gap: calc(var(--spacing) * 3);
    margin-bottom: calc(var(--spacing) * 8);
  }
  
  .project-preview {
    padding-top: 75%; /* 4:3 aspect ratio for mobile */
  }
  
  .project-card {
    gap: calc(var(--spacing) * 3);
    padding: calc(var(--spacing) * 5); /* Increased padding for mobile */
    margin-bottom: calc(var(--spacing) * 4);
  }
  
  .project-content {
    text-align: center; /* Center text on mobile */
    gap: calc(var(--spacing) * 4); /* Increased gap between elements on mobile */
  }
  
  .project-title {
    margin-bottom: calc(var(--spacing) * 2.5); /* Increased spacing after title on mobile */
    font-size: 22px; /* Slightly smaller font size on mobile */
  }
  
  .project-role {
    margin-bottom: calc(var(--spacing) * 2.5); /* Increased spacing after role on mobile */
  }
  
  .project-description {
    line-height: 1.6; /* Increased line height for better readability on mobile */
    margin-bottom: calc(var(--spacing) * 3); /* Add space after description */
    font-size: 14px; /* Smaller font size on mobile */
  }
  
  .experience-title {
    font-size: 20px;
  }
  
  .main-content {
    padding: calc(var(--spacing) * 3);
  }
  
  /* Adjust title and subtitle spacing */
  .subtitle-section {
    margin: calc(var(--spacing) * 4) 0;
  }
  
  .subtitle {
    font-size: 18px;
    padding: 0 calc(var(--spacing) * 3);
    line-height: 1.5;
  }
  
  /* Adjust section spacing */
  .section-header {
    padding-top: calc(var(--spacing) * 4);
    margin-bottom: calc(var(--spacing) * 5);
  }
  
  /* Adjust project content */
  .project-icon {
    width: 48px;
    height: 48px;
    margin-bottom: calc(var(--spacing) * 2);
  }
  
  /* Adjust experience section */
  .experience-section {
    margin-bottom: calc(var(--spacing) * 3);
  }
  
  .experience-item {
    padding: calc(var(--spacing) * 5);
    margin-bottom: calc(var(--spacing) * 4);
  }
  
  /* Adjust header */
  .header {
    padding: calc(var(--spacing) * 5) calc(var(--spacing) * 3);
  }
  
  .studio-name {
    font-size: 28px;
  }
  
  .section-label {
    font-size: 13px;
  }
  
  .contact-links {
    padding: calc(var(--spacing) * 3) 0;
  }
  
  .contact-link {
    font-size: 15px;
  }
}

/* Update the spacing and layout */
.subtitle-section {
  text-align: center;
  margin: calc(var(--spacing) * 2) 0 calc(var(--spacing) * 3);
}

.subtitle {
  font-size: 24px;
  color: var(--text-secondary);
  max-width: 600px;
  margin: 0 auto;
}

/* Update header styles */
.header {
  padding: calc(var(--spacing) * 4) 0;
  text-align: center;
}

.studio-name {
  font-size: 32px;
  font-weight: 600;
  letter-spacing: -0.02em;
}

/* Update projects grid */
.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: calc(var(--spacing) * 4);
  margin-bottom: calc(var(--spacing) * 12);
}

/* Update footer styles */ 
.footer {
  padding-top: calc(var(--spacing) * 2);
  margin-top: calc(var(--spacing) * 2);
}

.footer-content {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 calc(var(--spacing) * 8);
}

.contact-links {
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing) * 2);
}

.contact-link {
  font-size: 16px;
  color: var(--text-primary);
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: calc(var(--spacing) * 1);
  transition: transform 0.2s ease;
}

.contact-link:hover {
  transform: translateX(4px);
}

.arrow {
  font-size: 14px;
  opacity: 0.5;
}

.copyright {
  text-align: center;
  color: var(--text-tertiary);
  font-size: 12px;
  margin-top: calc(var(--spacing) * 8);
  padding: 0 calc(var(--spacing) * 8);
}

@media (max-width: 768px) {
  .footer-content {
    padding: 0 calc(var(--spacing) * 4);
  }
  
  .copyright {
    padding: 0 calc(var(--spacing) * 4);
  }
}

/* Add styles for the paintings gallery */
.paintings-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: calc(var(--spacing) * 3);
  padding: calc(var(--spacing) * 4) 0;
  max-width: 1000px;
  margin: 0 auto;
}

.painting-item {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: var(--radius);
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.painting-image {
  width: 100%;
  aspect-ratio: 3/4;
  object-fit: cover;
  border-radius: var(--radius);
}

.painting-details {
  padding: calc(var(--spacing) * 2) 0;
  font-size: 14px;
  color: var(--text-secondary);
}

.painting-title {
  font-weight: 500;
  margin-bottom: calc(var(--spacing) * 1);
  color: var(--text-primary);
}

.painting-medium {
  font-size: 12px;
  color: var(--text-tertiary);
  margin-bottom: calc(var(--spacing) * 0.5);
}

/* Modal styles for full-size view */
.painting-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: calc(var(--spacing) * 4);
}

.modal-content {
  max-width: 90vw;
  max-height: 90vh;
  position: relative;
}

.modal-image {
  max-width: 100%;
  max-height: 90vh;
  object-fit: contain;
  border-radius: var(--radius);
}

.close-button {
  position: absolute;
  top: calc(var(--spacing) * -6);
  right: 0;
  color: white;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: calc(var(--spacing) * 2);
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .paintings-gallery {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: calc(var(--spacing) * 2);
    padding: calc(var(--spacing) * 2);
  }

  .modal-content {
    max-width: 95vw;
  }

  .close-button {
    top: calc(var(--spacing) * -4);
    font-size: 20px;
  }
}

/* Add styles for painting details */
.painting-details {
  padding: calc(var(--spacing) * 2);
  font-size: 14px;
  color: var(--text-secondary);
}

.painting-title {
  font-weight: 500;
  margin-bottom: calc(var(--spacing) * 1);
  color: var(--text-primary);
}

.painting-medium {
  font-size: 12px;
  color: var(--text-tertiary);
}

/* CV section styles */
.cv-section {
  margin-top: calc(var(--spacing) * 8);
  text-align: center;
}

.cv-title {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: calc(var(--spacing) * 2);
}

.cv-subtitle {
  font-size: 24px;
  color: var(--text-secondary);
  margin-bottom: calc(var(--spacing) * 6);
}

.cv-bio {
  font-size: 16px;
  color: var(--text-secondary);
  margin-bottom: calc(var(--spacing) * 6);
  max-width: 800px;
  line-height: 1.6;
  margin-left: auto;
  margin-right: auto;
}

.exhibitions-title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: calc(var(--spacing) * 4);
}

.exhibitions-list {
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing) * 3);
  text-align: left;
  max-width: 800px;
  margin: 0 auto;
}

.exhibition-item {
  display: flex;
  align-items: flex-start;
  padding-bottom: calc(var(--spacing) * 3);
}

.exhibition-year {
  color: var(--text-secondary);
  min-width: 80px;
  margin-right: calc(var(--spacing) * 4);
}

.exhibition-title {
  font-size: 16px;
  line-height: 1.5;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .cv-title {
    font-size: 28px;
  }

  .cv-subtitle {
    font-size: 20px;
  }

  .exhibition-item {
    flex-direction: column;
    gap: calc(var(--spacing) * 1);
  }
  
  .exhibition-year {
    margin-bottom: calc(var(--spacing) * 1);
  }
}

/* Review quote styles */
.review-quote {
  font-size: 18px;
  color: var(--text-secondary);
  max-width: 800px;
  margin: 0 auto calc(var(--spacing) * 6) auto;
  line-height: 1.6;
  text-align: center;
}

.section-nav {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: calc(var(--spacing) * 3);
}

.cv-link {
  text-decoration: none;
  cursor: pointer;
  transition: opacity 0.2s ease;
}

.cv-link:hover {
  opacity: 0.7;
}

/* Add ID to CV section */
.cv-section {
  margin-top: calc(var(--spacing) * 8);
  text-align: center;
  scroll-margin-top: calc(var(--spacing) * 8); /* Ensures proper scroll position */
}

/* Add sparkles container styles */
.sparkles-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
}

/* Update existing styles for dark theme */
.App {
  background: transparent;
  min-height: 100vh;
  position: relative;
}

.main-content {
  position: relative;
  z-index: 1;
}

.portfolio-link {
  color: var(--text-primary);
}

.portfolio-link:hover {
  color: #ffffff;
  opacity: 0.8;
}

.contact-link {
  color: var(--text-primary);
}

.contact-link:hover {
  color: #ffffff;
  opacity: 0.8;
}

/* Add a subtle gradient overlay to the project cards */
.project-card::before {
  content: '';
  position: absolute;
  inset: 0;
  background: radial-gradient(circle at top right, rgba(255,255,255,0.03) 0%, transparent 50%);
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.project-card:hover::before {
  opacity: 1;
}

/* Add better spacing for very small screens */
@media (max-width: 380px) {
  .project-card,
  .experience-item {
    padding: calc(var(--spacing) * 4);
  }

  .project-content {
    gap: calc(var(--spacing) * 3.5); /* Adjusted gap for very small screens */
  }

  .main-content {
    padding: calc(var(--spacing) * 2);
  }

  .subtitle {
    font-size: 16px;
  }

  .studio-name {
    font-size: 24px;
  }
  
  /* Add more spacing between elements for better readability */
  .project-title {
    margin-bottom: calc(var(--spacing) * 3);
    font-size: 20px;
  }
  
  .project-role {
    margin-bottom: calc(var(--spacing) * 3);
    font-size: 14px;
  }
  
  .project-description {
    line-height: 1.7;
    font-size: 13px;
  }
  
  /* Adjust the View button spacing */
  .portfolio-link {
    margin-top: calc(var(--spacing) * 2);
    display: inline-block;
  }
}