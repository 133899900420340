.shooting-stars-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none;
  overflow: hidden;
  perspective: 1000px;
}

/* Star container for better positioning and animation */
.star-container {
  position: absolute;
  will-change: transform;
  animation: star-movement cubic-bezier(0.25, 0.1, 0.25, 1) 1;
  transform-style: preserve-3d;
}

/* Main shooting star element */
.shooting-star {
  position: relative;
  transform-origin: 0 50%;
  will-change: opacity, transform;
}

/* Star head (the bright point) */
.star-head {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background: #fff;
  box-shadow: 
    0 0 2px rgba(255, 255, 255, 0.8),
    0 0 4px rgba(255, 255, 255, 0.6),
    0 0 8px rgba(255, 255, 255, 0.4);
  z-index: 2;
  will-change: opacity;
}

/* Star trail */
.star-trail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform-origin: top center;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.9) 5%,
    rgba(255, 255, 255, 0.8) 10%,
    rgba(255, 255, 255, 0.6) 20%,
    rgba(255, 255, 255, 0.4) 40%,
    rgba(255, 255, 255, 0.2) 60%,
    rgba(255, 255, 255, 0.1) 80%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 100px;
  z-index: 1;
  will-change: opacity;
}

/* Premium star variations */
.premium-star {
  animation-timing-function: cubic-bezier(0.2, 0.3, 0.1, 1);
}

.premium-head {
  background: #fff;
  box-shadow: 
    0 0 2px rgba(255, 255, 255, 1),
    0 0 6px rgba(255, 255, 255, 0.8),
    0 0 12px rgba(255, 255, 255, 0.6),
    0 0 18px rgba(255, 255, 255, 0.4);
}

.premium-trail {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.95) 5%,
    rgba(255, 255, 255, 0.9) 10%,
    rgba(255, 255, 255, 0.8) 20%,
    rgba(255, 255, 255, 0.6) 40%,
    rgba(255, 255, 255, 0.4) 60%,
    rgba(255, 255, 255, 0.2) 80%,
    rgba(255, 255, 255, 0) 100%
  );
  box-shadow: 
    0 0 4px rgba(255, 255, 255, 0.3),
    0 0 8px rgba(255, 255, 255, 0.2);
}

/* Smooth fade out animation */
.fade-out {
  animation: fade-out 0.3s ease-out forwards;
}

/* Main star movement animation */
@keyframes star-movement {
  0% {
    opacity: 0;
    transform: translateX(0) translateY(0) rotate(inherit);
  }
  2% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateX(120vw) translateY(120vh) rotate(inherit);
  }
}

/* Fade out animation */
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Media query for reduced motion */
@media (prefers-reduced-motion: reduce) {
  .star-container {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    opacity: 0 !important;
  }
}
